import React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Banner.css";
//import banner_homepage from "../assets/img/banner_homepage.svg";

class Banner extends Component {
  render() {
    return (
      <Container className="banner-area-holder">
        <Row>
          <Col xs={6} md={4} className={this.props.id.size}>
            <h2 className="">{this.props.id.title1}</h2>
            <h1>{this.props.id.title2}</h1>
            <p
              dangerouslySetInnerHTML={{ __html: this.props.id.description }}
            ></p>
          </Col>
          <Col xs={12} md={8} className="">
            <img src={this.props.id.img} alt="" />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Banner;
