import React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Latest_podcasts.css";
import latest_podcasts from "../assets/img/latest_podcasts.svg";

class Latest_podcasts extends Component {
  getUrl = (url) => {
    let urlArr = [];
    if (url === "") {
      urlArr.push(<span className="brevemente_podcast">Brevemente</span>);
    } else {
      urlArr.push(
        <a href={url} target="_blank" rel="noreferrer">
          <span className="listen_podcast">OUVIR PODCAST</span>
        </a>
      );
    }
    return urlArr;
  };
  getLatest = () => {
    let col_val = 6;
    if (this.props.col_count === 3) {
      col_val = 3;
    }
    if (this.props.col_count === 4) {
      col_val = 4;
    } else {
    }
    //
    let latest = [];
    for (let i = 0; i < this.props.podcasts.length; i++) {
      latest.push(
        <Col xs={col_val} md={col_val} className="latest_holder ">
          <Row>
            <Col xs={6} md={6} className="img_logo_holder">
              <img
                src={this.props.podcasts[i].img}
                alt={this.props.podcasts[i].title}
              />
              {this.props.podcasts[i].logo && (
                <div className="sponsor">
                  <img
                    src={this.props.podcasts[i].logo}
                    alt={this.props.podcasts[i].title}
                  />
                </div>
              )}
              {this.props.podcasts[i].tag && (
                <div className="hastag">
                  <span>{this.props.podcasts[i].tag}</span>
                </div>
              )}
            </Col>
            <Col xs={6} md={6} className="">
              <h4>{this.props.podcasts[i].cat}</h4>
              <h3>{this.props.podcasts[i].title}</h3>
              <p>{this.props.podcasts[i].description}</p>
              {this.getUrl(this.props.podcasts[i].url)}
            </Col>
          </Row>
        </Col>
      );
    }
    return latest;
  };
  render() {
    return (
      <Container className="latest-area-holder">
        <Row>
          <Col>
            <h2>{this.props.looptitle}</h2>
          </Col>
        </Row>
        <Row>{this.getLatest()}</Row>
      </Container>
    );
  }
}

export default Latest_podcasts;
