import React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Parceiros.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import parceiro from "../assets/img/parceiro.png";

class Parceiros extends Component {
  getParceiros = () => {
    let latest = [];
    for (let i = 0; i <= 15; i++) {
      latest.push(
        <Col xs={12} md={3} className="parceiro_holder">
          <img src={parceiro} alt="" />
        </Col>
      );
    }
    return latest;
  };
  render() {
    return (
      <div className="parceiros_page">
        <Header />
        <Banner />
        <Container className="parceiros-holder">
          <Row>{this.getParceiros()}</Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default Parceiros;
