import React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./QuemSomos.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import Podcast from "../components/Podcast";
import LatestPodcasts from "../components/Latest_podcasts";

class QuemSomos extends Component {
  render() {
    return (
      <div className="quem_somos_holder">
        <Header />
        <Banner />
        <Container className="description_area">
          <Row>
            <Col>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                eget rhoncus massa, id placerat ipsum. Pellentesque commodo
                metus sit amet sapien luctus lobortis. Suspendisse a dapibus
                lacus, eu luctus felis. Sed non dapibus quam. Donec orci augue,
                ornare eu vulputate quis, laoreet ac nisi. Duis euismod, ante at
                fermentum suscipit, diam risus porta metus, vel finibus dui odio
                nec erat. Maecenas fringilla sagittis volutpat. Quisque et
                pharetra nisi, quis volutpat felis. Nullam efficitur enim id
                vehicula pulvinar.
                <br />
                <br />
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                posuere cubilia curae; Vestibulum ornare massa quis nibh finibus
                eleifend. In nisl augue, pulvinar at porttitor ut, convallis at
                tellus. In ipsum purus, iaculis at sem interdum, sollicitudin
                luctus eros. Nunc pharetra pretium tincidunt. Pellentesque ipsum
                orci, pulvinar in suscipit non, condimentum quis justo.
                Vestibulum posuere hendrerit nisi, vitae molestie dui gravida a.
                Morbi lobortis elit et dignissim bibendum. Vivamus et rutrum
                enim. Vestibulum odio dolor, ultrices at massa in, rhoncus
                tincidunt diam. Donec ullamcorper ante interdum velit accumsan
                egestas. Aenean vitae semper velit, quis efficitur orci. Donec
                in lacus faucibus, condimentum est vitae, ullamcorper enim.
                Praesent efficitur consectetur arcu eu egestas. Pellentesque sit
                amet laoreet turpis, et tincidunt risus. Pellentesque habitant
                morbi tristique senectus et netus et malesuada fames ac turpis
                egestas.
                <br />
                <br />
                Curabitur vestibulum quam vitae sem convallis molestie. Integer
                sem lacus, auctor quis sagittis sit amet, vestibulum vel neque.
                Ut blandit, nulla venenatis laoreet finibus, sapien metus
                sollicitudin ligula, et commodo risus augue eget sem. Nullam id
                mattis diam, non vestibulum dolor. Etiam finibus rhoncus augue,
                ut hendrerit leo ultricies sed. Donec accumsan mauris ut eros
                condimentum tristique. Ut nec varius ligula.
                <br />
                <br />
                Pellentesque nulla eros, placerat sit amet lobortis eget,
                ultrices eget nisl. Vestibulum condimentum molestie est,
                faucibus pellentesque lorem pulvinar a. Aenean sed ultricies
                erat. Nam ac laoreet ex, quis consequat sem. Cras eros dui,
                commodo tristique laoreet ac, hendrerit sed ipsum. Cras sed
                mattis nibh. Integer eleifend lorem non dolor vulputate, a
                condimentum tellus cursus. Class aptent taciti sociosqu ad
                litora torquent per conubia nostra, per inceptos himenaeos.
                Curabitur ut volutpat eros. Quisque dignissim risus id odio
                elementum, eu euismod risus convallis. Vestibulum augue lacus,
                fringilla eget nisl sit amet, interdum gravida urna. Nulla
                facilisi. Aenean lobortis lacus et felis porta porttitor. Donec
                sit amet tempus diam. Ut vehicula, orci quis semper consectetur,
                mi eros sollicitudin dui, a vestibulum ante orci nec purus.
                <br />
                <br />
                Phasellus id nibh ac justo eleifend vestibulum. Sed bibendum
                tellus at molestie tristique. Suspendisse condimentum tristique
                risus vel bibendum. Nunc sed scelerisque lectus, vitae consequat
                felis. Proin pretium, tortor ac accumsan varius, turpis enim
                porttitor nunc, eu pellentesque lorem sapien quis turpis.
                Praesent et fringilla nisl. Curabitur lectus purus, pulvinar sit
                amet interdum id, placerat ac justo. Nulla sed metus imperdiet,
                venenatis ligula vel, volutpat odio. Vivamus varius elit vitae
                lorem tristique facilisis. Pellentesque a sodales arcu, eu
                dictum dui. Sed tincidunt sodales felis, ac sagittis mauris
                mattis id. Etiam scelerisque mauris ut ex congue, non porttitor
                lacus elementum. Nulla ultrices vitae turpis eget pretium. Morbi
                tristique, erat eget tempor dapibus, libero risus ornare purus,
                vel facilisis lacus mauris ac diam. Duis elementum neque ut enim
                vehicula commodo.
              </p>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default QuemSomos;
