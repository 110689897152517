import React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Footer.css";
import logo from "../assets/img/logo.svg";

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="footer_bg">
          <Container>
            <Row>
              <Col xs={12} md={4}>
                <img src={logo} alt="Logo HH Podcasts" className="logo" />
              </Col>
              <Col xs={12} md={8} className="mailto_container">
                <p className="valign_middle">
                  Fale connosco:{" "}
                  <a href="mailto:pod@healthcarehits.pt">
                    pod@healthcarehits.pt
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    );
  }
}

export default Footer;
