import React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Homepage.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import LatestPodcasts from "../components/Latest_podcasts";
import ReactGA from "react-ga";

class Homepage extends Component {
  render() {
    ReactGA.initialize("UA-210804561-1");
    ReactGA.pageview(window.location.hash + window.location.search);
    return (
      <div>
        <Header />
        <Banner
          id={{
            title1: "HEALTHCARE HITS",
            title2: "PODCASTS",
            description:
              "Ouvir podcasts estimula várias áreas dos hemisférios direito e esquerdo do cérebro.  Os podcasts são uma maneira moderna e eﬁciente de aumentar a nossa base de conhecimento sobre praticamente qualquer tópico. <br /><br /> Bem vindo à primeira plataforma nacional de podcasts na área da saúde em Portugal.",
            img: "banner_homepage.svg",
            size: "bigLettering",
          }}
        />
        <LatestPodcasts
          col_count={2}
          looptitle="PODCASTS"
          podcasts={[
            {
              cat: "Medicina Geral e Familiar",
              title: "Abordagem Multimodal da Dor Parte I - Dr. Tiago Costa",
              description:
                "“Ainda bem que vieste!” é um projeto criado por internos e para internos. Ao longo dos vários episódios vamos retratar simulações de consulta sobre diferentes temas úteis para a formação dos internos de Medicina Geral e Familiar. Neste primeiro episódio falamos com o Dr. Tiago Costa, médico interno de Neurocirurgia do Hospital de Santo António, sobre Abordagem Multimodal da Dor.",
              logo: "",
              img: "podcast_5.jpeg",
              tag: "",
              url: "https://podcasters.spotify.com/pod/show/healthcare-hits/episodes/Abordagem-Multimodal-da-Dor---Parte-I-e243l1q",
            },
            {
              cat: "Medicina Veterinária",
              title: "Parasitas externos",
              description:
                "O primeiro episódio desta série de podcasts vai abordar a temática dos parasitas externos, mais concretamente pulgas, carraças e da variedade de doenças que se podem transmitir aos animais de companhia, mas também aos seus tutores.",
              logo: "msd_logo.svg",
              img: "podcast_1.jpeg",
              tag: "#protectourfuturetoo",
              url: "https://healthcarehits.pt/#/podcast/parasitas_externos",
            },
            {
              cat: "Medicina Veterinária",
              title: "Leishmaniose",
              description:
                "As temperaturas progressivamente mais altas estão a levar a um aumento da época de risco dos insetos vetores da Leishmaniose, permitindo que estes se colonizem em áreas onde antes não eram encontrados.",
              logo: "msd_logo.svg",
              img: "podcast_2.jpg",
              tag: "#protectourfuturetoo",
              url: "https://healthcarehits.pt/#/podcast/leishmaniose",
            },
            {
              cat: "Medicina Veterinária",
              title: "Parasitas internos",
              description:
                "O debate irá desenvolver-se em torno da toxoplasmose, toxocarose (lombrigas) e ténias, apontando formas de diagnóstico, tratamento e proteção dos animais de companhia e dos seus tutores.",
              logo: "msd_logo.svg",
              img: "podcast_3.jpg",
              tag: "#protectourfuturetoo",
              url: "https://healthcarehits.pt/#/podcast/parasitas_internos",
            },
            {
              cat: "Medicina Veterinária",
              title: "Doenças zoonóticas",
              description:
                "Neste último debate serão discutidas doenças como a raiva, que ainda representa uma preocupação a nível mundial, ou a leptospirose e medidas de proteção contra estas enfermidades.",
              logo: "msd_logo.svg",
              img: "podcast_4.jpg",
              tag: "#protectourfuturetoo",
              url: "https://healthcarehits.pt/#/podcast/doencas_zoonoticas",
            },
          ]}
        />
        <Banner
          id={{
            title1: "Disponível",
            title2: "Brevemente",
            description:
              "Áreas de especialidade médica<br />Área reservada a profissionais de saúde<br />App para download dos podcasts",
            img: "/banner_homepage_2.svg",
            size: "smallLettering",
          }}
        />
        <Footer />
      </div>
    );
  }
}

export default Homepage;
