import React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Header.css";
import logo from "../assets/img/logo.svg";
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <header>
        <Container>
          <Row>
            <Col xs={6} md={4}>
              <Link to="/">
                <img src={logo} alt="Logo HH Podcasts" className="logo" />
              </Link>
            </Col>
            <Col xs={12} md={8}></Col>
          </Row>
        </Container>
      </header>
    );
  }
}

export default Header;
