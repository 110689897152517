import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import Homepage from "./pages/Homepage";
import ParasitasExternos from "./pages/ParasitasExternos";
import ParasitasInternos from "./pages/ParasitasInternos";
import DoencasZoonoticas from "./pages/DoencasZoonoticas";
import Leishmaniose from "./pages/Leishmaniose";
import Parceiros from "./pages/Parceiros";
import Parceiro from "./pages/Parceiro";
import Especialidade from "./pages/Especialidade";
import QuemSomos from "./pages/QuemSomos";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/" exact component={Homepage}></Route>
    </Switch>
  </Router>,
  document.getElementById("root")
);
//<React.StrictMode>
//{
//<Login />
//<Register />
//<Parceiros />
//<Parceiro />
//<Especialidade />
//<QuemSomos />
//<Contactos />
//  }
//</React.StrictMode>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
